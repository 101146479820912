import { FunctionComponent, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import { Application } from "@applications/models/Application";
import { useGetAssociatedApplications } from "@applications/services/ApplicationService";
import { SelectedApplication } from "@users/applicationuser/models/SelectedApplication";

import { EditUserAssociation } from "../models/EditUserAssociation";
import EditApplicationAssociation from "./EditApplicationAssociation";

interface Props {
  userId: string;
  userApplications: EditUserAssociation[];
  selectedApplications: SelectedApplication[];
  isDisabled?: boolean;
  onAppRoleChange: (
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean
  ) => void;
  onAppRemove: (application: Application) => void;
}

function getCheck(
  userApplications: EditUserAssociation[],
  applicationId: string
): boolean {
  const userAssociation = userApplications?.find(
    item => item.application.id === applicationId
  );

  return userAssociation !== undefined;
}

const noRoles: string[] = [];

function getRoles(
  userApplications: EditUserAssociation[],
  applicationId: string
): string[] {
  const userAssociation = userApplications.find(
    item => item.application.id === applicationId
  );
  if (userAssociation === null) return [];

  return userAssociation?.userRoles ?? noRoles;
}

const EditApplicationAssociations: FunctionComponent<Props> = ({
  userId,
  userApplications,
  selectedApplications,
  isDisabled = false,
  onAppRoleChange,
  onAppRemove
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "user.applications" });

  const { applications, isLoading, error } = useGetAssociatedApplications();

  const renderChildren = useCallback(
    (children: ReactNode): ReactNode => {
      return (
        <>
          <Typography variant="smallTitle">{t("section_title")}</Typography>
          <div className="flex flex-col gap-5">{children}</div>
        </>
      );
    },
    [t]
  );

  if (isLoading)
    return renderChildren(<LoadingComponent message={t("load.message")} />);
  if (error)
    return renderChildren(<ErrorComponent message={t("load.error.message")} />);

  if (applications!.length === 0)
    return renderChildren(<>{t("load.none.message")}</>);

  if (!applications) return null;

  return renderChildren(
    <>
      {applications.map((application: Application) => {
        return (
          <EditApplicationAssociation
            key={"appAssoc_" + application.id + "_" + userId}
            applicationId={application.id}
            applicationName={application.name}
            selectedApplications={selectedApplications}
            checked={getCheck(userApplications, application.id)}
            isDisabled={isDisabled}
            userRoles={getRoles(userApplications, application.id)}
            onAppRoleChange={onAppRoleChange}
            onAppRemove={() => {
              const userApplication = userApplications.find(
                item => item.application.id === application.id
              );
              if (userApplication) onAppRemove(application);
            }}
          />
        );
      })}
    </>
  );
};

export default EditApplicationAssociations;
