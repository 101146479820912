import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { IOption, Select } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import { useGetCountries } from "@countries/services/use-countries";

interface Props {
  label: string;
  selectedCountryCode: string | undefined;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void;
}

const CountryDropDown: FC<Props> = ({
  label,
  selectedCountryCode,
  disabled = false,
  onChange
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "countries" });

  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  const { countries, isLoading, error } = useGetCountries();

  useEffect(() => {
    if (countries) {
      setOptions(
        countries.map(({ code, name }) => ({
          value: code,
          label: name
        }))
      );
    }
  }, [countries]);

  useEffect(() => {
    if (options.length > 0) {
      if (selectedCountryCode) {
        const foundOption = options.find(
          option => option.value.toString() === selectedCountryCode
        );
        setSelectedOption(foundOption ?? null);
      }
    }
  }, [selectedCountryCode, options]);

  const handleChange = (option: IOption) => {
    setSelectedOption(option);
    if (onChange) onChange(option.value as string);
  };

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error) return <ErrorComponent message={t("load.error.message")} />;

  if (countries === undefined || countries.length === 0)
    return <div data-testid="country-no-data">{t("load.none.message")}</div>;

  return (
    <Select
      data-testid="country"
      disabled={disabled}
      label={label}
      placeholder={label}
      key="country"
      options={options}
      selected={selectedOption}
      enableSearch={true}
      searchPlaceholder={t("search.placeholder")}
      onChange={handleChange}
    />
  );
};

export default CountryDropDown;
