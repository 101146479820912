import { useCallback, useMemo } from "react";

import axios, { AxiosResponse } from "axios";

import { occApiEndPoints } from "@api/OccApiEndpoints";
import { useGetOrganizationDetails } from "@organization/services/use-organization";
import { AddPaymentMethodResponse } from "@store/checkout/models/AddPaymentMethodResponse";
import { AddToCartResponse } from "@store/checkout/models/AddToCartResponse";
import { CartResponse } from "@store/checkout/models/CartResponse";
import { OrderEntries } from "@store/checkout/models/OrderEntries";
import { OrderResponse } from "@store/checkout/models/OrderResponse";

import { useUserContext } from "@hooks/useUserContext";

export function useOccApis() {
  const { user, getAccessToken } = useUserContext();
  const { organization } = useGetOrganizationDetails();
  const baseSite = organization?.countryCode?.toLowerCase() ?? undefined;

  const createACart = useCallback(async () => {
    if (baseSite) {
      try {
        const accessToken = await getAccessToken();
        const userId = user.id;
        const url = occApiEndPoints.createACart(userId, baseSite);

        const response = await axios.post<
          CartResponse,
          AxiosResponse<CartResponse>
        >(url, undefined, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        return response.data.code;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return new Error(error.message);
        }
      }
    }
    return new Error("Failed to create cart");
  }, [baseSite, getAccessToken, user.id]);

  const addProductToCart = useCallback(
    async (cartId: string, productCode: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const userId = user.id;
          const url = occApiEndPoints.addProductToCart(
            userId,
            cartId,
            baseSite
          );

          const data: OrderEntries = {
            orderEntries: [
              {
                product: {
                  code: productCode
                },
                quantity: 1
              }
            ]
          };

          const response = await axios.post<
            AddToCartResponse,
            AxiosResponse<AddToCartResponse>
          >(url, data, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          return response.data.cartModifications.length > 0
            ? response.data.cartModifications[0].statusCode
            : "";
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [getAccessToken, baseSite, user.id]
  );

  const setPaymentType = useCallback(
    async (cartId: string, paymentType: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const url = occApiEndPoints.addPaymentType(
            user.id,
            cartId,
            paymentType,
            baseSite
          );

          const response = await axios.put<
            AddPaymentMethodResponse,
            AxiosResponse<AddPaymentMethodResponse>
          >(url, undefined, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          return response.data.paymentType;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [getAccessToken, baseSite, user.id]
  );

  const addDeliveryAddress = useCallback(
    async (addressId: string, cartId: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const url = occApiEndPoints.addDeliveryAddress(
            user.id,
            cartId,
            addressId,
            baseSite
          );
          const response = await axios.put(url, undefined, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          return response;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [getAccessToken, baseSite, user.id]
  );

  const setCardDetails = useCallback(
    async (cardId: string, cartId: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const url = occApiEndPoints.setCardDetails(
            user.id,
            cartId,
            cardId,
            baseSite
          );
          const response = await axios.put(url, undefined, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          return response;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [getAccessToken, baseSite, user.id]
  );

  const createOrder = useCallback(
    async (cartId: string) => {
      if (baseSite) {
        try {
          const accessToken = await getAccessToken();
          const url = occApiEndPoints.createOrder(user.id, cartId, baseSite);
          const response = await axios.post<
            OrderResponse,
            AxiosResponse<OrderResponse>
          >(url, undefined, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          return response.data.code;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return new Error(error.message);
          }
        }
      }
    },
    [getAccessToken, baseSite, user.id]
  );

  return useMemo(
    () => ({
      createACart,
      addProductToCart,
      setPaymentType,
      addDeliveryAddress,
      setCardDetails,
      createOrder
    }),
    [
      createACart,
      addProductToCart,
      setPaymentType,
      addDeliveryAddress,
      setCardDetails,
      createOrder
    ]
  );
}
