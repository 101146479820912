import { ValidationResult } from "./models/ValidationResult";

export const maxLength =
  (max: number, errorMessage: () => string) => (value: string) => {
    if (value.trim().length <= max) return <ValidationResult>{ isValid: true };
    return <ValidationResult>{
      isValid: false,
      errorMessage: errorMessage()
    };
  };
