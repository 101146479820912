import {
  Configuration,
  RedirectRequest,
  SilentRequest
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_APP_CLIENT_ID as string,
    authority: import.meta.env.VITE_APP_AUTHORITY as string,
    knownAuthorities: [import.meta.env.VITE_APP_KNOWN_AUTHORITIES],
    redirectUri: "/auth-response",
    postLogoutRedirectUri: "/signedout"
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["openid", "offline_access"]
};
export const tokenRequest: SilentRequest = {
  scopes: [import.meta.env.VITE_APP_BACKEND_API_SCOPE as string]
};
