import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { ITab, TabMenu } from "@destination/components";

import { useTabIndex } from "@hooks/useTabIndex";

const AccountInformation: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "store.account_information"
  });
  const tabs: ITab[] = [
    {
      id: "creditCard",
      label: t("credit_card_tab.label"),
      content: (
        <div data-testid="tab-CreditCards">
          <Outlet />
        </div>
      )
    },
    {
      id: "addresses",
      label: t("addresses_tab.label"),
      content: (
        <div className="rounded-tl-none" data-testid="tab-Addresses">
          <Outlet />
        </div>
      )
    }
  ];

  const { currentTabIndex } = useTabIndex(tabs);
  const navigate = useNavigate();
  const handleChangeTab = (tabIndex: number) => {
    if (tabIndex === 0) {
      return navigate("");
    }

    navigate(tabs[tabIndex].id.toLowerCase());
  };
  return (
    <div data-testid="account-information-tabs">
      <TabMenu value={currentTabIndex} onChange={handleChangeTab} tabs={tabs} />
    </div>
  );
};

export default AccountInformation;
