import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { RadioButton, Typography } from "@destination/components";

interface Props {
  deliveryMode: string | null;
  onChange: (value: string | null) => void;
}

const DeliveryMode: FunctionComponent<Props> = ({ deliveryMode, onChange }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "store.checkout.delivery_mode"
  });
  const [isActive, setIsActive] = useState(deliveryMode !== null);

  function handleRadioChange(): void {
    setIsActive(true);
    onChange("Online");
  }
  return (
    <div data-testid="delivery-mode">
      <Typography variant="smallTitle" className="mb-5 font-bold">
        {t("label")}
      </Typography>
      <RadioButton
        checked={isActive}
        data-testid="delivery-mode-online"
        onClick={handleRadioChange}
        label={
          <div className="flex">
            <Typography variant="smallTitle" className="font-bold">
              {t("online")}
            </Typography>
          </div>
        }
      />
    </div>
  );
};

export default DeliveryMode;
