import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Card, Typography } from "@destination/components";

import errorCircle from "@icons/error-circle.svg";

interface Props {
  errorMessage: string;
  onClose: () => void;
}
const OrderFailedMessage: FunctionComponent<Props> = ({
  errorMessage,
  onClose
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.failed_order" });
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const errorDetails = () => {
    return <>{errorMessage}</>;
  };
  return (
    <div
      className="flex flex-col text-center"
      data-testid="ordered-failed-message"
    >
      <img
        className="h-6 sm:h-6"
        src={errorCircle}
        alt="error circle"
        data-testid="errorIcon"
      />

      <Typography variant="pageTitle" className="mt-5">
        {t("title")}
      </Typography>

      <Typography variant="description" className="mt-5">
        {t("error.message")}
      </Typography>

      <Card
        className="justify-center shadow-transparent"
        isExpanded={showDetails}
        onExpand={() => setShowDetails(true)}
        onCollapse={() => setShowDetails(false)}
        expandableCardContent={errorDetails()}
      >
        <Typography variant="smallTitle">{t("show_details")}</Typography>
      </Card>

      <div className="flex flex-row-reverse gap-3 pt-5">
        <Button data-testid="ordered-failed-close" onClick={onClose}>
          {t("close.label")}
        </Button>
      </div>
    </div>
  );
};

export default OrderFailedMessage;
