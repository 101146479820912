import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Input, useAlert } from "@destination/components";

import { useUpdateOrganization } from "@organization/services/use-organization-update";
import { maxLength } from "@validators/MaxLengthValidator";
import { isNotEmpty } from "@validators/NotEmptyValidator";
import { isNotRegExMatch } from "@validators/RegExValidator";

import { useInput } from "@hooks/useInput";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { useUserContext } from "@hooks/useUserContext";

interface Props {
  label: string;
  value: string | undefined;
}

const OrganizationName: FunctionComponent<Props> = ({ label, value }) => {
  const { t } = useTranslation();

  const { user } = useUserContext();
  const [initialValue, setInitialValue] = useState(value);
  const [isUpdating, setIsUpdating] = useState(false);
  const { notifyError } = useAlert();
  const { updateName } = useUpdateOrganization();
  const { refresh: refreshUserContext } = useOrganizationContext();

  const {
    value: organizationName,
    isValid,
    errorMessage,
    valueChangeHandler,
    reset
  } = useInput(
    [
      maxLength(256, () =>
        t("validation.string.max-length", {
          fieldName: label,
          maxLength: 256
        })
      ),
      isNotEmpty(() => t("validation.string.not-empty", { fieldName: label })),
      isNotRegExMatch(/[<>]/, () =>
        t("validation.string.illegal-characters", {
          fieldName: label,
          characters: "<, >"
        })
      )
    ],
    initialValue
  );
  const saveChanges = async () => {
    if (!isValid || organizationName === initialValue) return;

    setIsUpdating(true);

    const { isSuccess } = await updateName(organizationName);

    if (!isSuccess) {
      reset();
      notifyError({
        header: t("not_saved")
      });
    } else {
      setInitialValue(organizationName);
      refreshUserContext();
    }
    setIsUpdating(false);
  };

  return (
    <Input
      data-testid={"organization-name"}
      id="name"
      type="text"
      label={label}
      error={!isValid}
      disabled={isUpdating || !user.isAdministrator}
      helperText={!isValid ? errorMessage : undefined}
      value={organizationName}
      onChange={event => {
        valueChangeHandler(event.target.value);
      }}
      onBlur={saveChanges}
    />
  );
};

export default OrganizationName;
