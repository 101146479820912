import axios from "axios";

import { organizationEndpoints } from "@api/Endpoints";

import { OrganizationNameResponse } from "../models/OrganizationNameResponse";

export const getOrganizationName = async (
  accessToken: string
): Promise<string | null> => {
  const url = organizationEndpoints.organizationName();
  const request = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  const response = await axios.get<OrganizationNameResponse>(url, request);
  const name = response.data?.name;

  return name ?? null;
};
