import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import PageHelmet from "@components/PageHelmet";

import CountryDropDown from "@countries/components/CountryDropDown";
import Applications from "@organization/components/Applications";
import OrganizationName from "@organization/components/OrganizationName";
import OrganizationTypeDropDown from "@organization/components/OrganizationTypeDropDown";
import { useGetOrganizationDetails } from "@organization/services/use-organization";

const OrganizationForm: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization" });

  const { organization, isLoading, error } = useGetOrganizationDetails();

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <>
      <PageHelmet title={t("page_title")} />
      <div className="flex w-[342px] flex-col gap-11 py-5">
        <OrganizationName label={t("name.label")} value={organization?.name} />
        <Applications />
        <OrganizationTypeDropDown
          label={t("type.label")}
          disabled={true}
          selectedType={organization?.type}
        ></OrganizationTypeDropDown>
        <CountryDropDown
          label={t("country.label")}
          disabled={true}
          selectedCountryCode={organization?.countryCode}
        ></CountryDropDown>
      </div>
    </>
  );
};

export default OrganizationForm;
