import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import Addresses from "@store/checkout/components/Addresses";
import { Address } from "@store/checkout/models/Address";
import { useGetBillingAddresses } from "@store/services/CommonService";

interface Props {
  addressId: string | null;
  onChange: (selectedAddress: Address | null) => void;
}

const BillingAddresses: FunctionComponent<Props> = ({
  addressId,
  onChange
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });

  const { addresses, isLoading, error } = useGetBillingAddresses();

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  if (addresses && addresses.length === 0)
    return <ErrorComponent message={t("load.none.message")} />;

  return (
    <Addresses
      addresses={addresses}
      selectedAddressId={addressId}
      onChange={onChange}
    />
  );
};

export default BillingAddresses;
