import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";

import AddressComponent from "@store/checkout/components/Address";
import { Address } from "@store/checkout/models/Address";
import { Price } from "@store/products/models/Price";

interface Props {
  selectedPrice: number;
  productPrice: Price;
  deliveryMode: string;
  billingAddress: Address;
}

const Summary: FunctionComponent<Props> = ({
  selectedPrice,
  productPrice,
  deliveryMode,
  billingAddress
}) => {
  const { t: tProduct } = useTranslation(undefined, {
    keyPrefix: "store.product"
  });
  const { t } = useTranslation(undefined, { keyPrefix: "store.checkout" });

  const renderPriceSummary = () => {
    function generatePriceOption() {
      const id = selectedPrice === productPrice.yearly ? "yearly" : "monthly";
      return (
        <Typography
          variant="smallTitle"
          className="font-bold"
          data-testid={`product-price-${id}-value`}
        >
          {tProduct(`prices.${id}`, {
            currency: productPrice.currency,
            value: selectedPrice
          })}
        </Typography>
      );
    }

    return (
      <div data-testid="price-summary">
        <Typography variant="smallTitle" className="mb-5 font-bold">
          {tProduct("prices.label")}
        </Typography>
        <Typography variant="smallTitle" className="font-bold">
          {productPrice.amount !== null &&
            tProduct("prices.onetime", {
              currency: productPrice.currency,
              value: selectedPrice
            })}
          {(productPrice.yearly !== null || productPrice.monthly !== null) &&
            generatePriceOption()}
        </Typography>
      </div>
    );
  };

  const renderDeliveryModeSummary = () => {
    return (
      <div data-testid="delivery-mode-summary">
        <Typography variant="smallTitle" className="mb-2 mt-5 font-bold">
          {t("delivery_mode.label")}
        </Typography>
        <Typography variant="smallText">{deliveryMode}</Typography>
      </div>
    );
  };

  const renderAddressSummary = () => {
    return (
      <div data-testid="address-summary">
        <Typography variant="smallTitle" className="mb-2 mt-5 font-bold">
          {t("billing-address.label")}
        </Typography>
        <AddressComponent address={billingAddress} />
      </div>
    );
  };
  return (
    <div data-testid="summary">
      <Card>
        {renderPriceSummary()}
        {renderDeliveryModeSummary()}
        {renderAddressSummary()}
      </Card>
    </div>
  );
};

export default Summary;
