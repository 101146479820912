import React from "react";

import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

import { AlertProvider, Sidebar } from "@destination/components";

import { msalConfig } from "./authConfig";

import "./i18n";
import "../node_modules/@destination/components/dist/index.css";
import "./main.css";

import { SWRConfig } from "swr";

import LoadingPage from "@pages/LoadingPage";

import { router } from "./routes";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
let account: AccountInfo | null = null;
if (accounts.length > 0) {
  account = accounts[0];
  msalInstance.setActiveAccount(account);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const swrConfig = { shouldRetryOnError: false };

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sidebar.Provider>
      <React.Suspense fallback={<LoadingPage />}>
        <MsalProvider instance={msalInstance}>
          <SWRConfig value={swrConfig}>
            <HelmetProvider>
              <AlertProvider>
                <RouterProvider router={router} />
              </AlertProvider>
            </HelmetProvider>
          </SWRConfig>
        </MsalProvider>
      </React.Suspense>
    </Sidebar.Provider>
  </React.StrictMode>
);
