import { FunctionComponent } from "react";

import PageTitle from "@components/PageTitle";

import OrganizationInfo from "@organization/components/OrganizationInfo";
import OrganizationTabMenu from "@organization/components/OrganizationTabMenu";

import { useOrganizationContext } from "@hooks/useOrganizationContext";

const OrganizationPage: FunctionComponent = () => {
  const { organization } = useOrganizationContext();

  return (
    <div>
      <PageTitle title={organization.name} />
      <OrganizationInfo />
      <OrganizationTabMenu />
    </div>
  );
};

export default OrganizationPage;
