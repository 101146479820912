const baseUrl = import.meta.env.VITE_APP_BACKEND_API_URL as string;

export const userEndpoints = {
  userRoleName: (): string => `${baseUrl}/users/me/role`,
  idTokenHint: (organizationId: string): string =>
    `${baseUrl}/users/me/organizations/${organizationId}/id-token-hint`,
  organizations: () => `${baseUrl}/users/me/organizations`,
  getDefaultOrganization: (): string =>
    `${baseUrl}/users/me/organizations/default`,
  setDefaultOrganization: () => `${baseUrl}/users/me/organizations/default`
};
