import { useCallback, useMemo } from "react";

import { useSWRConfig } from "swr";

import { organizationEndpoints } from "@api/Endpoints";

import { putData } from "@hooks/usePutData";

export function useUpdateOrganization() {
  const { mutate } = useSWRConfig();

  const updateName = useCallback(
    async (name: string) => {
      const url = organizationEndpoints.updateOrganizationName();
      const result = await putData<{ name: string }>(url, {
        name: name
      });
      await mutate(organizationEndpoints.organizationDetails());
      return result;
    },
    [mutate]
  );

  return useMemo(() => ({ updateName }), [updateName]);
}
