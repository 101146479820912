import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { IOption, MultiSelect } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import { Application } from "@applications/models/Application";
import { useGetAssociatedApplications } from "@applications/services/ApplicationService";

const Applications: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "organization.applications"
  });
  const [options, setOptions] = useState<IOption[]>([]);

  const { applications, isLoading, error } = useGetAssociatedApplications();

  useEffect(() => {
    if (applications) {
      const options: IOption[] = [];
      applications.forEach((application: Application) => {
        options.push({ label: application.name, value: application.id });
      });
      setOptions(options);
    }
  }, [applications]);

  if (isLoading) return <LoadingComponent message={t("load.message")} />;

  if (error) return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div data-testid="associated-applications">
      <MultiSelect
        label={t("label")}
        placeholder={
          applications!.length === 0 ? t("load.none.message") : undefined
        }
        disabled={true}
        options={options}
        selected={applications!.length === 0 ? null : options}
        onChange={() => undefined}
      />
    </div>
  );
};

export default Applications;
