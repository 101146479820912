import { createBrowserRouter, Navigate } from "react-router-dom";

import IsAdministrator from "@account/components/IsAdministrator";
import Language from "@account/components/Language";
import UserAccountInformation from "@account/components/UserAccountInformation";
import SettingsPage from "@account/pages/SettingsPage";
import ApplicationPage from "@applications/pages/ApplicationPage";
import OrganizationForm from "@organization/components/OrganizationForm";
import OrganizationUsers from "@organization/components/OrganizationUsers";
import OrganizationPage from "@organization/pages/OrganizationPage";
import HomePage from "@pages/HomePage";
import PageNotFound from "@pages/NotFoundPage";
import SignedOutPage from "@pages/SignedOutPage";
import AccountInformation from "@store/account/components/AccountInformation";
import Addresses from "@store/addresses/components/Addresses";
import StoreHomePage from "@store/pages/StoreHomePage";
import PaymentDetails from "@store/payments/components/PaymentDetails";
import Products from "@store/products/components/Products";
import PurchaseHistory from "@store/purchases/components/PurchaseHistory";
import MySubscriptions from "@store/subscriptions/components/MySubscriptions";

import { Root } from "./root";

export const router = createBrowserRouter([
  {
    path: "signedout",
    element: <SignedOutPage />
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true, // <-- match on parent, i.e. "/"
        element: <HomePage />
      },
      { path: "/auth-response", element: <Navigate to="/" replace /> },
      {
        path: "organization",
        element: <OrganizationPage />,
        children: [
          { path: "", element: <OrganizationForm /> },
          {
            path: "users",
            element: <OrganizationUsers />
          }
        ]
      },
      { path: "applications", element: <ApplicationPage /> },
      {
        path: "store",
        element: (
          <IsAdministrator>
            <StoreHomePage />
          </IsAdministrator>
        ),
        children: [
          { path: "", element: <Products /> },
          { path: "purchases", element: <PurchaseHistory /> },
          { path: "subscriptions", element: <MySubscriptions /> },
          {
            path: "account",
            element: <AccountInformation />,
            children: [
              { path: "", element: <PaymentDetails /> },
              { path: "addresses", element: <Addresses /> }
            ]
          }
        ]
      },
      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          { path: "", element: <UserAccountInformation />, index: true },
          { path: "language", element: <Language /> },
          { path: "notification", element: null }
        ]
      },

      {
        path: "*",
        element: <PageNotFound />
      }
    ]
  }
]);
