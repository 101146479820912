import { ChangeEvent, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

const defaultSearchName = "search";

export const useSearchQueryString = (prefix?: string) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchName, setSearchName] = useState(defaultSearchName);

  useEffect(() => {
    if (prefix && prefix !== "")
      setSearchName(`${prefix}_${defaultSearchName}`);
    else setSearchName(defaultSearchName);
  }, [prefix]);

  useEffect(() => {
    if (searchParams.has(searchName))
      setSearchValue(searchParams.get(searchName) ?? "");
    else setSearchValue("");
  }, [searchName, searchParams]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const currentParams = new URLSearchParams(window.location.search);

    if (inputValue) {
      currentParams.set(searchName, inputValue);
    } else {
      currentParams.delete(searchName);
    }
    setSearchParams(currentParams);
  };

  return { searchValue, handleSearch };
};
