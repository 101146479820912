import { useMemo } from "react";

import useSWR from "swr";

import { occApiEndPoints } from "@api/OccApiEndpoints";
import { useGetOrganizationDetails } from "@organization/services/use-organization";
import { Address } from "@store/checkout/models/Address";

import { useUserContext } from "@hooks/useUserContext";
import { fetcher } from "@utils/swrUtils";

export function useGetBillingAddresses() {
  const { user } = useUserContext();
  const { organization } = useGetOrganizationDetails();
  const baseSite = organization?.countryCode.toLowerCase() ?? undefined;

  const url = occApiEndPoints.getAddresses(
    organization?.id ?? "",
    user.id,
    baseSite ?? "",
    "billing"
  );

  const { data, error, isLoading } = useSWR<{ addresses: Address[] }, Error>(
    url,
    fetcher
  );
  return useMemo(
    () => ({ addresses: data?.addresses, isLoading, error }),
    [data, isLoading, error]
  );
}
