import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { SortDirection } from "@utils/sortUtil";

const defaultActiveSortName = "activeSort";
const defaultDirectionName = "direction";

const toSortDirection = (sortDirection: string | null): SortDirection => {
  if (!sortDirection) {
    return undefined;
  }
  switch (sortDirection.toLowerCase()) {
    case "ascending":
      return "ascending";
    case "descending":
      return "descending";
  }
  return sortDirection as SortDirection;
};

export const useSortQueryString = (prefix?: string) => {
  const [activeSort, setActiveSort] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>(undefined);

  const [activeSortName, setActiveSortName] = useState(defaultActiveSortName);
  const [directionName, setDirectionName] = useState(defaultDirectionName);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (prefix && prefix !== "") {
      setActiveSortName(`${prefix}_${defaultActiveSortName}`);
      setDirectionName(`${prefix}_${defaultDirectionName}`);
    } else {
      setActiveSortName(defaultActiveSortName);
      setDirectionName(defaultDirectionName);
    }
  }, [prefix]);

  useEffect(() => {
    if (searchParams.has(activeSortName))
      setActiveSort(searchParams.get(activeSortName) ?? "");
    else setActiveSort("");
    if (searchParams.has(directionName))
      setSortDirection(toSortDirection(searchParams.get(directionName)));
    else setSortDirection(undefined);
  }, [activeSortName, directionName, searchParams]);

  const handleSort = (header: string) => {
    const currentParams = new URLSearchParams(window.location.search);

    if (sortDirection) {
      currentParams.set(directionName, sortDirection);
    } else {
      currentParams.delete(directionName);
    }

    if (activeSort === header) {
      if (sortDirection === "ascending") {
        currentParams.set(directionName, "descending");
      } else {
        currentParams.set(directionName, "ascending");
      }
    } else {
      currentParams.set(activeSortName, header);
      currentParams.set(directionName, "ascending");
    }

    setSearchParams(currentParams);
  };

  return { activeSort, sortDirection, handleSort };
};
