import { OrganizationUser } from "@organization/models/OrganizationUser";

import {
  getTimeValue,
  PropertyType,
  SortDirection,
  sortValues
} from "@utils/sortUtil";

export function sort(
  users: OrganizationUser[] | undefined,
  activeSort: string | undefined,
  sortDirection: SortDirection
): OrganizationUser[] {
  return sortValues(users, activeSort, sortDirection, getSortValues);
}

function getSortValues(
  activeSort: string | undefined,
  a: OrganizationUser,
  b: OrganizationUser
) {
  let valueA: PropertyType;
  let valueB: PropertyType;
  switch (activeSort) {
    case "name":
      valueA = a.displayName;
      valueB = b.displayName;
      break;
    case "email":
      valueA = a.emailAddress;
      valueB = b.emailAddress;
      break;
    case "role":
      valueA = a.organizationRole;
      valueB = b.organizationRole;
      break;
    case "created-on":
      valueA = getTimeValue(new Date(a.createdOn));
      valueB = getTimeValue(new Date(b.createdOn));
      break;
  }
  return { valueA, valueB };
}
