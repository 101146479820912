import { FunctionComponent } from "react";

import { Helmet } from "react-helmet-async";

interface Props {
  title: string;
}

const PageHelmet: FunctionComponent<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title}${title !== "" ? " - " : ""}OMNIS Portal`}</title>{" "}
    </Helmet>
  );
};

export default PageHelmet;
