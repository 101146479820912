import { FunctionComponent } from "react";

import ApplicationsPanel from "./ApplicationsPanel";
import QuickAccessPanel from "./QuickAccessPanel";
import QuickActionsPanel from "./QuickActionsPanel";

const Dashboard: FunctionComponent = () => {
  return (
    <div className="flex gap-10" data-testid="dashboard">
      <div className="flex flex-1 flex-col">
        <QuickAccessPanel />
        <ApplicationsPanel />
      </div>
      <div className="w-[200px]">
        <QuickActionsPanel />
      </div>
    </div>
  );
};

export default Dashboard;
