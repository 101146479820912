import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import SectionTitle from "@components/SectionTitle";

import InviteUserButton from "@users/organizationuser/components/InviteUserButton";

import { useUserContext } from "@hooks/useUserContext";

const QuickActionsPanel: FunctionComponent = () => {
  const { user } = useUserContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboard.quick-actions"
  });

  return (
    <div className="flex flex-col" data-testid="quickActionsPanel">
      <SectionTitle title={t("section_title")} />
      <div className="flex flex-col gap-10">
        {!user.isAdministrator && (
          <Typography variant="description">No actions</Typography>
        )}
        <InviteUserButton />
      </div>
    </div>
  );
};

export default QuickActionsPanel;
