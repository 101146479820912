import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { useDialog } from "@destination/components";
import { IDrawer } from "@destination/components/dist/components/Drawer/Drawer";

import { DrawerContext } from "./DrawerContext";

export interface DrawerProps {
  title: IDrawer["title"];
  content: IDrawer["children"];
}

const useDrawer = () => {
  const { setDrawer, setShowDrawer } = useContext(DrawerContext);

  const { trigger } = useDialog();
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation();

  const show = (drawer: DrawerProps) => {
    setDrawer(drawer);
    setShowDrawer(true);
  };

  const hide = (isFormTouched?: boolean) => {
    if (isFormTouched! && isFormTouched) {
      trigger({
        variant: "warning",
        header: t("hideDrawer.confirm.title"),
        content: t("hideDrawer.confirm.message"),
        confirmButton: tCommon("button.confirm.label"),
        onConfirm: () => {
          setShowDrawer(false);
          setDrawer(undefined);
        },
        cancelButton: tCommon("button.cancel.label"),
        onCancel: () => {
          /* Do nothing */
        }
      });
    } else {
      setShowDrawer(false);
      setDrawer(undefined);
    }
  };

  return { showDrawer: show, hideDrawer: hide };
};

export default useDrawer;
