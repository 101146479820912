import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { RadioButton, Typography } from "@destination/components";

interface Props {
  price: number;
  selectedPrice: number;
  currency: string;
  onChange: (value: number | null) => void;
}

const OneTimePrice: FunctionComponent<Props> = ({
  price,
  selectedPrice,
  currency,
  onChange
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.product" });
  const [isActive, setIsActive] = useState<boolean>(selectedPrice !== null);

  function handleRadioChange() {
    setIsActive(true);
    onChange(price);
  }
  return (
    <RadioButton
      checked={isActive}
      data-testid="onetime-price"
      onClick={handleRadioChange}
      label={
        <div className="flex">
          <Typography variant="smallTitle" className="font-bold">
            {t("prices.onetime", {
              currency: currency,
              value: price
            })}
          </Typography>
        </div>
      }
    />
  );
};

export default OneTimePrice;
