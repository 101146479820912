import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import {
  IconButton,
  Tooltip,
  useAlert,
  useDialog
} from "@destination/components";

import { Application } from "@applications/models/Application";
import { useRemoveUserApplication } from "@users/applicationuser/services/ApplicationUserService";

import TrashIcon from "@icons/trash.svg?react";

import { ApplicationUser } from "../models/ApplicationUser";

interface Props {
  users: ApplicationUser[];
  application: Application;
}

const DeleteApplicationUserButton: FunctionComponent<Props> = ({
  users,
  application
}) => {
  const { trigger } = useDialog();
  const { notifyError, notifySuccess } = useAlert();

  const { t } = useTranslation(undefined, {
    keyPrefix: "application.users"
  });
  const { t: tCommon } = useTranslation();

  const { deleteApp: deleteApplicationUser } = useRemoveUserApplication();

  function handleDelete(): void {
    trigger({
      variant: "warning",
      header: t("delete.confirm.title"),
      content: t("delete.confirm.message"),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: async () => {
        await removeUsers();
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        /* Do nothing */
      },
      "data-testid": "delete-user"
    });
  }

  const removeUsers = async () => {
    for (const user of users) {
      const { error } = await deleteApplicationUser(
        application.id,
        user.userId
      );
      if (error) {
        notifyError({
          header: t("delete.error.message", {
            user: user.displayName,
            application: application.name
          })
        });
      } else {
        notifySuccess({
          header: t("delete.success.message", {
            user: user.displayName,
            application: application.name
          })
        });
      }
    }
  };

  return (
    <Tooltip message={t("delete.tooltip")}>
      <IconButton onClick={handleDelete} data-testid="delete-icon-button">
        <TrashIcon className="h-3 sm:h-4" />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteApplicationUserButton;
