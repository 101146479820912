import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Button, useDialog } from "@destination/components";

import TrashIcon from "@icons/trash.svg?react";

interface Props {
  disabled: boolean;
  onConfirm: () => void;
}

const DiscardPurchaseButton: FunctionComponent<Props> = ({
  disabled,
  onConfirm
}) => {
  const { trigger } = useDialog();

  const { t } = useTranslation(undefined, {
    keyPrefix: "store.checkout.purchase.discard"
  });
  const { t: tCommon } = useTranslation();

  const handleDiscard = () => {
    trigger({
      variant: "error",
      header: t("confirm.title"),
      content: t("confirm.message"),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: () => {
        onConfirm();
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        ("");
      },
      "data-testid": "discard-purchase"
    });
  };

  return (
    <Button
      data-testid="discard-purchase-button"
      disabled={disabled}
      icon={<TrashIcon className="h-3 sm:h-4" />}
      onClick={handleDiscard}
      variant="discreet"
    >
      {t("label")}
    </Button>
  );
};

export default DiscardPurchaseButton;
