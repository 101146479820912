import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { SearchInput } from "@destination/components";

import PageHelmet from "@components/PageHelmet";

import AllProducts from "@store/products/components/AllProducts";

import { useSearchQueryString } from "@hooks/useSearchQueryString";

const Products: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });

  const { searchValue, handleSearch } = useSearchQueryString();

  return (
    <div data-testid="products-container">
      <PageHelmet title={`${t("products_tab.label")}`} />
      <div className="flex w-full flex-row-reverse">
        <div className="w-[500px]">
          <SearchInput
            data-testid="search-products"
            value={searchValue}
            placeholder={t("search.placeholder")}
            onChange={handleSearch}
          />
        </div>
      </div>
      <AllProducts searchValue={searchValue} />
    </div>
  );
};

export default Products;
