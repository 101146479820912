const baseUrl = `${
  import.meta.env.VITE_APP_BACKEND_API_URL as string
}/organizations/current`;

export const organizationEndpoints = {
  organizationName: () => `${baseUrl}/name`,
  organizationDetails: () => baseUrl,
  updateOrganizationName: () => `${baseUrl}/name`,
  organizationExternalIds: () => `${baseUrl}/externalIds`
};

export const organizationUserEndpoints = {
  organizationUsers: () => `${baseUrl}/users`,
  addUser: () => `${baseUrl}/users`,
  updateUserRole: (userId: string) => `${baseUrl}/users/${userId}`,
  removeUser: (userId: string) => `${baseUrl}/users/${userId}`
};

export const applicationEndpoints = {
  organizationApplications: () => `${baseUrl}/applications`,
  applicationUsers: (applicationId: string) =>
    `${baseUrl}/applications/${applicationId}/users`,
  applicationRoles: (applicationId: string) =>
    `${baseUrl}/applications/${applicationId}/roles`,
  userApplications: (userId: string) =>
    `${baseUrl}/users/${userId}/applications`,
  updateOrDeleteApplicationUsers: (
    applicationId: string,
    userId: string
  ): string => `${baseUrl}/applications/${applicationId}/users/${userId}`
};

export const storeEndPoints = {
  getAllProducts: () => `${baseUrl}/products`,
  getSuggestedProducts: () => `${baseUrl}/products/suggested`,
  getOrders: () => `${baseUrl}/orders`
};

export const subscriptionEndPoints = {
  getSubscriptions: () => `${baseUrl}/subscriptions`,
  getSubscriptionById: (subscriptionId: string) =>
    `${baseUrl}/subscriptions/${subscriptionId}`,
  cancelSubscription: (subscriptionId: string) =>
    `${baseUrl}/subscriptions/${subscriptionId}`
};

export const addressEndPoints = {
  getAddresses: () => `${baseUrl}/addresses`,
  createAddress: () => `${baseUrl}/addresses`,
  deleteAddress: (addressId: string) => `${baseUrl}/addresses/${addressId}`,
  setDefaultBillingAddress: () => `${baseUrl}/billing-addresses/default`
};
