import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@destination/components";

import InviteUserForm from "@users/organizationuser/components/InviteUserForm";

import useDrawer from "@hooks/useDrawer";
import { useUserContext } from "@hooks/useUserContext";
import UserIcon from "@icons/user.svg";

const InviteUserButton: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization.users" });

  const { user } = useUserContext();
  const { showDrawer, hideDrawer } = useDrawer();

  const inviteUser = (): void => {
    showDrawer({
      title: t("invite.title"),
      content: (
        <InviteUserForm
          onCancel={hideDrawer}
          onSuccess={() => {
            hideDrawer();
          }}
        />
      )
    });
  };

  if (!user.isAdministrator) return null;

  return (
    <Button
      data-testid="invite-user-button"
      icon={<img className="h-3 sm:h-4" src={UserIcon} alt="check circle" />}
      onClick={inviteUser}
      variant="primary"
    >
      {t("invite.label")}
    </Button>
  );
};

export default InviteUserButton;
