import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { useTranslation } from "react-i18next";

import ErrorPage from "@pages/ErrorPage";
import LoadingPage from "@pages/LoadingPage";

import useIdToken from "@hooks/useIdToken";
import { useUserContext } from "@hooks/useUserContext";

import { Organization } from "./models/Organization";
import { OrganizationContext } from "./OrganizationContext";
import { getOrganizationName } from "./services/organizationService";

interface Props {
  children: ReactNode;
}

const OrganizationContextProvider: FunctionComponent<Props> = ({
  children
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });

  const { organizationId } = useIdToken();

  const [organization, setOrganization] = useState<Organization>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

  const { getAccessToken } = useUserContext();

  const refresh = useCallback((): void => {
    setRefreshFlag(flag => !flag);
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (!organizationId) {
        setOrganization(undefined);
        return;
      }

      const accessToken = await getAccessToken();

      const name = await getOrganizationName(accessToken);

      setOrganization(new Organization(organizationId, name ?? ""));
    };

    setIsLoading(true);
    initialize()
      .catch(() => setError(t("signin.organization.load.error.message")))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, refreshFlag]);

  const contextValue = useMemo(
    () => ({
      organization: organization!,
      refresh
    }),
    [organization, refresh]
  );

  const memoizedChildren = useMemo(
    () => <>{children}</>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isLoading) return <LoadingPage />;

  if (error) return <ErrorPage message={error} />;

  if (!organization) return <LoadingPage />;

  return (
    <OrganizationContext.Provider value={contextValue}>
      {memoizedChildren}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContextProvider;
