import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "@destination/components";

import { useUserContext } from "@hooks/useUserContext";

const UserAccountInformation: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });
  const { user } = useUserContext();

  return (
    <div className="mt-8 flex flex-col gap-8">
      <Input
        id="name"
        label={t("displayName.label")}
        data-testid="name"
        value={user.name}
        disabled
      />
      <Input
        id="email"
        label={t("email.label")}
        data-testid="email"
        value={user.email}
        disabled
      />
    </div>
  );
};

export default UserAccountInformation;
