import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import { useUserContext } from "@hooks/useUserContext";

interface Props {
  id: string;
  value: Date;
}

const SubscriptionDateSection: FunctionComponent<Props> = ({ id, value }) => {
  const { user } = useUserContext();
  const { t } = useTranslation(undefined, { keyPrefix: "store.subscription" });

  const dateFormatter = new Intl.DateTimeFormat(user.language, {
    dateStyle: "short"
  });

  return (
    <div className="mt-10 text-wrap" data-testid={`subscription-section-${id}`}>
      <Typography variant="description" className="font-bold">
        {t(`${id}.label`)}
      </Typography>
      <Typography variant="description" className="mt-1 text-wrap">
        {dateFormatter.format(value)}
      </Typography>
    </div>
  );
};

export default SubscriptionDateSection;
