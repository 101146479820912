import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

interface Props {
  value: string;
}

const ProductAbout: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });
  return (
    <div className="mt-4 text-wrap">
      <Typography
        variant="description"
        className="font-bold"
        data-testid="product-about-label"
      >
        {t("product.about.label")}
      </Typography>
      <Typography
        variant="description"
        className="mt-1 text-wrap"
        data-testid="product-about-value"
      >
        {value}
      </Typography>
    </div>
  );
};

export default ProductAbout;
