import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";

import OrganizationApplications from "../components/OrganizationApplications";

const ApplicationPage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "applications" });

  return (
    <>
      <PageTitle title={t("page_title")} />
      <OrganizationApplications />
    </>
  );
};

export default ApplicationPage;
