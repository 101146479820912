import { createContext, Dispatch, SetStateAction } from "react";

import { DrawerProps } from ".";

interface IDrawerContext {
  setDrawer: Dispatch<SetStateAction<DrawerProps | undefined>>;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
}

export const DrawerContext = createContext<IDrawerContext>({
  setDrawer: () => undefined,
  setShowDrawer: () => false
});
