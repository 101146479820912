import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button, useAlert } from "@destination/components";

import useDrawer from "@hooks/useDrawer";
import PlusIcon from "@icons/plus.svg?react";

import AddNewAddress from "./AddNewAddress";

const AddNewAddressButton: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });

  const { showDrawer, hideDrawer } = useDrawer();
  const { notifyError, notifySuccess } = useAlert();

  const handleOnSuccess = () => {
    hideDrawer();
    notifySuccess({
      header: t("add.success.message"),
      "data-testid": "add-address-success-alert"
    });
  };
  const handleOnError = () => {
    hideDrawer();
    notifyError({
      header: t("add.error.message"),
      "data-testid": "add-address-error-alert"
    });
  };

  const handleAddAddress = () => {
    showDrawer({
      title: "",
      content: (
        <AddNewAddress onError={handleOnError} onSuccess={handleOnSuccess} />
      )
    });
  };
  return (
    <Button
      data-testid="add-new-address-button"
      icon={<PlusIcon />}
      variant="primary"
      onClick={handleAddAddress}
    >
      {t("add.label")}
    </Button>
  );
};

export default AddNewAddressButton;
