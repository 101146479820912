import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

interface Props {
  value: string;
}

const SubscriptionStatus: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.subscription" });

  return (
    <div
      className="mt-10 text-wrap"
      data-testid={`subscription-section-status`}
    >
      <Typography variant="description" className="font-bold">
        {t(`status.label`)}
      </Typography>
      <Typography variant="description" className="mt-1 text-wrap">
        {value}
      </Typography>
    </div>
  );
};

export default SubscriptionStatus;
