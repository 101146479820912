import { useMemo } from "react";

import useSWR from "swr";

import { applicationEndpoints } from "@api/Endpoints";
import { OrganizationApplicationsResponse } from "@organization/models/OrganizationApplicationsResponse";

import { fetcher } from "@utils/swrUtils";

export function useGetAssociatedApplications() {
  const url = applicationEndpoints.organizationApplications();

  const { data, error, isLoading } = useSWR<
    OrganizationApplicationsResponse,
    Error
  >(url, fetcher);

  return useMemo(
    () => ({ applications: data?.applications, isLoading, error }),
    [data, isLoading, error]
  );
}
