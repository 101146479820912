import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

import { useOrganizationContext } from "@hooks/useOrganizationContext";
import CheckIcon from "@icons/check-mark.svg?react";

import DefaultOrganizationToggleButton from "./DefaultOrganizationToggleButton";

const AccountMenuCurrentOrganizationHeader: FunctionComponent = () => {
  const { organization: currentOrganization } = useOrganizationContext();

  return (
    <li
      data-testid="current-organization-header"
      key={currentOrganization.id}
      className="group/item flex cursor-pointer flex-row items-center gap-2 bg-white py-1 hover:bg-gray-100"
    >
      {currentOrganization.id && (
        <CheckIcon
          data-testid="current-organization-check"
          className="my-3 h-3 max-w-none hover:min-w-0 sm:h-4"
        />
      )}
      <Typography variant="description" className="grow">
        {currentOrganization.name}
      </Typography>
      <div className="float-right">
        <DefaultOrganizationToggleButton organization={currentOrganization} />
      </div>
    </li>
  );
};

export default AccountMenuCurrentOrganizationHeader;
