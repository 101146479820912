import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

import { CreditCardDetail } from "@store/creditcard/models/CreditCardDetail";

import CreditCardIcon from "@icons/credit_card.svg";
import MasterCardIcon from "@icons/master_card_logo.svg";
import VisaCardIcon from "@icons/visa_card_logo.svg";

interface Prop {
  paymentDetail: CreditCardDetail;
}

const CreditCardComponent: FunctionComponent<Prop> = ({ paymentDetail }) => {
  const renderCard = (cardType: string) => {
    if (cardType === "visa") {
      return <img src={VisaCardIcon} alt="visa" />;
    } else if (cardType === "master") {
      return <img src={MasterCardIcon} alt="master" />;
    } else {
      return <img src={CreditCardIcon} alt="credit card" />;
    }
  };

  return (
    <div
      className="flex flex-row items-center gap-2"
      data-testid={`credit-card-${paymentDetail.id}`}
      key={paymentDetail.id}
    >
      {renderCard(paymentDetail.cardType.code)}
      <Typography variant="smallText">{paymentDetail.cardNumber}</Typography>
      <Typography variant="smallText">
        {paymentDetail.expiryMonth} / {paymentDetail.expiryYear}
      </Typography>
    </div>
  );
};

export default CreditCardComponent;
