import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Typography } from "@destination/components";

import PageContent from "@components/PageContent";
import PageTitle from "@components/PageTitle";

const SignedOutPage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "account.signedout" });

  return (
    <PageContent>
      <PageTitle title={t("page_title")} helmetTitle={t("title")} />
      <Typography variant="description" className="mb-2 mt-2">
        {t("description")}
      </Typography>
      <Link to="/">
        <Button variant="accent">{t("signin_again")}</Button>
      </Link>
    </PageContent>
  );
};

export default SignedOutPage;
