import { useContext } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import {
  DialogProvider,
  Sidebar,
  SidebarContext
} from "@destination/components";

import Header from "@components/Header/Header";
import {
  FooterNavigation,
  MenuNavigation
} from "@components/navigation/NavigationMenu";
import PageContent from "@components/PageContent";
import RequireAuth from "@components/RequireAuth";

import DrawerProvider from "@hooks/useDrawer/DrawerProvider";
import OrganizationContextProvider from "@hooks/useOrganizationContext/OrganizationContextProvider";
import UserContextProvider from "@hooks/useUserContext/UserContextProvider";

export const Root = () => {
  const navigate = useNavigate();
  const { isCollapsed } = useContext(SidebarContext);
  return (
    <div
      data-testid="page-content"
      className={`flex h-screen ${!isCollapsed ? "ml-[150px] pl-0" : "ml-1"}`}
    >
      <RequireAuth>
        <UserContextProvider>
          <OrganizationContextProvider>
            <DialogProvider>
              <DrawerProvider>
                <Sidebar
                  menuItems={<MenuNavigation />}
                  footerItems={<FooterNavigation />}
                  onClickBranding={() => navigate("/")}
                />
                <PageContent>
                  <Header />
                  <Outlet />
                </PageContent>
              </DrawerProvider>
            </DialogProvider>
          </OrganizationContextProvider>
        </UserContextProvider>
      </RequireAuth>
    </div>
  );
};
