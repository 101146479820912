import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Button, Typography } from "@destination/components";

import checkIcon from "@icons/checkCircle.svg";

interface Props {
  nextBillingDate: Date;
  onFinish: () => void;
}

const SubscriptionCancelledMessage: FunctionComponent<Props> = ({
  nextBillingDate,
  onFinish
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "store.subscription.cancel"
  });
  return (
    <div
      className="flex flex-col text-center"
      data-testid="subscription-cancelled-message"
    >
      <img
        className="h-6 sm:h-6"
        src={checkIcon}
        alt="check circle"
        data-testid="check-icon"
      />

      <Typography variant="pageTitle" className="mt-5">
        {t("success.message")}
      </Typography>

      <Typography variant="description" className="mt-5">
        {t("thanks.message")}
      </Typography>

      <Typography variant="description" className="mt-5">
        {t("info.message", { nextBillingDate: nextBillingDate })}
      </Typography>

      <div className="mt-5 flex justify-center">
        <Button variant="ghost" data-testid="back-to-store" onClick={onFinish}>
          {t("back.label")}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCancelledMessage;
