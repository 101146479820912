import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Switch, useAlert, useDialog } from "@destination/components";

import { useSetDefaultBillingAddress } from "@store/addresses/services/use-billing-address-default";

interface Props {
  addressId: string;
  addressName: string;
  isDefault: boolean;
}

const SetDefaultBillingAddressSwitch: FC<Props> = ({
  addressId,
  addressName,
  isDefault
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });
  const { t: tCommon } = useTranslation();

  const { notifyError, notifySuccess } = useAlert();
  const { trigger } = useDialog();
  const { setDefaultBillingAddress } = useSetDefaultBillingAddress();
  const handleSwitch = () => {
    if (!isDefault) {
      trigger({
        variant: "warning",
        header: t("default-billing-address.confirm.title"),
        content: t("default-billing-address.confirm.message"),
        confirmButton: tCommon("button.confirm.label"),
        onConfirm: () => {
          void setDefaultBillingAddress({ addressId: addressId })
            .then(response => {
              if (response instanceof Error) {
                notifyError({
                  header: t("default-billing-address.error.message"),
                  "data-testid": "set-default-billing-address-error-alert"
                });
              } else {
                notifySuccess({
                  header: t("default-billing-address.success.message", {
                    address: addressName
                  }),
                  "data-testid": "set-default-billing-address-success-alert"
                });
              }
            })
            .catch(() => {
              notifyError({
                header: t("default-billing-address.error.message"),
                "data-testid": "set-default-billing-address-error-alert"
              });
            });
        },
        cancelButton: tCommon("button.cancel.label"),
        onCancel: () => {
          /* Do nothing */
        },
        "data-testid": "set-default-billing-address-confirmation-dialog"
      });
    }
  };

  return (
    <Switch
      type="radio"
      label={
        isDefault
          ? t("default-billing-address.label")
          : t("set-default-billing-address.label")
      }
      variant="alternative"
      checked={isDefault}
      readOnly={isDefault}
      data-testid={`default-billing-address-switch-${addressId}`}
      onChange={() => handleSwitch()}
    />
  );
};

export default SetDefaultBillingAddressSwitch;
