import { AxiosError } from "axios";

import { axiosInstance } from "./axiosInstance";

export const fetcher = async (url: string) => {
  const response = await axiosInstance.get(url).catch(function (
    error: AxiosError
  ) {
    throw new Error(error.message);
  });

  if (response.data?.errorMessage) {
    throw new Error(response.data.errorMessage);
  }

  return response.data;
};
