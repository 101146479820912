import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox, IOption, MultiSelect } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import { useGetApplicationRoles } from "@users/applicationuser/services/ApplicationUserService";

interface Props {
  applicationId: string;
  applicationName: string;
  isDisabled?: boolean;
  onAppRoleChange: (
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean,
    isChecked: boolean,
    errorMessage: string | undefined
  ) => void;
}

const ApplicationAssociation: FunctionComponent<Props> = ({
  applicationId,
  applicationName,
  isDisabled = false,
  onAppRoleChange
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "user.applications" });
  const { t: tApplicationRoles } = useTranslation(undefined, {
    keyPrefix: "application.roles"
  });
  const { t: tCommon } = useTranslation();

  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IOption[] | null>(
    null
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { applicationRoles, limit, isLoading, error } =
    useGetApplicationRoles(applicationId);

  useEffect(() => {
    const roles: IOption[] = [];
    if (applicationRoles.length > 0) {
      applicationRoles.forEach((role: string) => {
        roles.push({ label: role, value: role });
      });
    }
    setOptions(roles);
  }, [applicationRoles]);

  const handleAppRoleChange = (options: IOption[] | null) => {
    setSelectedOptions(options);
    if (options === null) {
      setIsValid(false);
      const fieldName = t("role.label");
      const errorMessage = tCommon("validation.string.not-empty", {
        fieldName: fieldName
      });
      setErrorMessage(errorMessage);
      onAppRoleChange(
        applicationId,
        applicationName,
        [],
        false,
        isChecked,
        errorMessage
      );
      return [];
    }
    if (limit > -1 && options.length > limit) {
      setIsValid(false);
      const errorMessage = t("role.validation.max-roles", { limit: limit });
      setErrorMessage(errorMessage);
      onAppRoleChange(
        applicationId,
        applicationName,
        options.map(item => item.value) as string[],
        false,
        isChecked,
        errorMessage
      );
      return [];
    }

    setIsValid(true);
    setErrorMessage(undefined);
    onAppRoleChange(
      applicationId,
      applicationName,
      options.map(item => item.value) as string[],
      true,
      isChecked,
      undefined
    );
    return options.map(item => item.value) as string[];
  };

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;

    setSelectedOptions(null);
    setDisabled(!checkedValue);
    setIsChecked(checkedValue);

    const isValid = !checkedValue;
    setIsValid(isValid);
    if (isValid) {
      setErrorMessage(undefined);
    } else {
      const fieldName = t("role.label");
      const errorMessage = tCommon("validation.string.not-empty", {
        fieldName: fieldName
      });
      setErrorMessage(errorMessage);
    }
    onAppRoleChange(
      applicationId,
      applicationName,
      [],
      isValid,
      checkedValue,
      errorMessage
    );
  };

  if (isLoading)
    return <LoadingComponent message={tApplicationRoles("load.message")} />;
  if (error)
    return <ErrorComponent message={tApplicationRoles("load.error.message")} />;

  return (
    <div
      className="flex flex-col gap-2"
      data-testid={`application-association-${applicationId}`}
    >
      <Checkbox
        data-testid={`invite-user-checkbox-${applicationId}`}
        label={applicationName}
        checked={isChecked}
        variant="alternative"
        disabled={(limit !== 0 && applicationRoles.length === 0) || isDisabled}
        onChange={handleCheckChange}
      />
      {limit !== 0 && applicationRoles.length === 0 && (
        <span>{tApplicationRoles("load.none.message")}</span>
      )}
      {limit !== 0 && applicationRoles.length > 0 && (
        <div data-testid={`invite-associated-applications-${applicationId}`}>
          <MultiSelect
            placeholder={tApplicationRoles("placeholder")}
            disabled={disabled || isDisabled}
            error={!isValid && !disabled}
            helperText={!isValid ? errorMessage : undefined}
            options={options}
            selected={selectedOptions}
            onChange={handleAppRoleChange}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicationAssociation;
