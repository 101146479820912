import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";

import ProductImageGallery from "@store/checkout/components/ProductImageGallery";
import ProductAbout from "@store/products/components/ProductAbout";
import ProductCategory from "@store/products/components/ProductCategory";
import ProductDescription from "@store/products/components/ProductDescription";
import ProductName from "@store/products/components/ProductName";
import { Product } from "@store/products/models/Product";

interface Props {
  product: Product;
  isExpandable?: boolean;
}

const ProductDetails: FunctionComponent<Props> = ({
  product,
  isExpandable = true
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const productDetails = () => {
    return (
      <div data-testid="collapsible-product-details">
        <ProductImageGallery productImages={product.images} />
        <ProductAbout value={product.about} />
        <ProductCategory value={product.platform} />
      </div>
    );
  };

  return (
    <div data-testid="checkout-product-details">
      <ProductName value={product.name} />
      <ProductDescription value={product.description} />
      {isExpandable && (
        <Card
          className="justify-start shadow-transparent"
          isExpanded={showDetails}
          onExpand={() => setShowDetails(true)}
          onCollapse={() => setShowDetails(false)}
          expandableCardContent={productDetails()}
          data-testid="show-hide-details-card"
        >
          <Typography variant="smallTitle">{t("show_details")}</Typography>
        </Card>
      )}
      {!isExpandable && productDetails()}
    </div>
  );
};

export default ProductDetails;
