import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import { useUserContext } from "@hooks/useUserContext";

const AccountMenuOrganizationRoleHeader: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });
  const { user } = useUserContext();

  return (
    <div className="mb-2 text-left">
      <Typography variant="smallTitle" data-testid="organization-role">
        {`${t("your_role.label")}: ${user.roleName}`}
      </Typography>
    </div>
  );
};

export default AccountMenuOrganizationRoleHeader;
