import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Stepper } from "@destination/components";

import Delivery from "@store/checkout/components/Delivery";
import DiscardPurchaseButton from "@store/checkout/components/DiscardPurchaseButton";
import PaymentMethods from "@store/checkout/components/PaymentMethodSelector";
import ProductDetails from "@store/checkout/components/ProductDetails";
import Summary from "@store/checkout/components/Summary";
import { Address } from "@store/checkout/models/Address";
import { useOccApis } from "@store/checkout/services/CheckoutService";
import ProductPrice from "@store/products/components/ProductPrice";
import { Product } from "@store/products/models/Product";

interface Props {
  product: Product;
  onSuccess: (orderId: string) => void;
  onError: (error: Error) => void;
  onDiscard: () => void;
}

export const ProductCheckout: FunctionComponent<Props> = ({
  product,
  onSuccess,
  onError,
  onDiscard
}) => {
  const { t: tCheckout } = useTranslation(undefined, {
    keyPrefix: "store.checkout"
  });
  const { t: tCommon } = useTranslation();

  const [cartId, setCartId] = useState<string | undefined>();
  const [cartStatusCode, setCartStatusCode] = useState<string | undefined>();

  const [deliveryAddressStatus, setDeliveryAddressStatus] = useState<
    boolean | undefined
  >();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState<number>(-1);
  const [validStep, setValidStep] = useState(true);
  const [deliveryMode, setDeliveryMode] = useState<string | null>(null);
  const [deliveryAddress, setDeliveryAddress] = useState<Address | null>(null);
  const [paymentTypeStatus, setPaymentTypeStatus] = useState<
    boolean | undefined
  >();

  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);

  const {
    createACart,
    addProductToCart,
    setPaymentType,
    setCardDetails,
    addDeliveryAddress,
    createOrder
  } = useOccApis();

  useEffect(() => {
    if (
      cartId &&
      cartStatusCode &&
      cartStatusCode === "success" &&
      selectedPaymentMethod
    ) {
      const paymentMethodValue =
        selectedPaymentMethod !== "account" ? "card" : selectedPaymentMethod;
      void setPaymentType(cartId, paymentMethodValue)
        .then(response => {
          if (response instanceof Error) {
            onError(response);
          } else if (paymentMethodValue === "card") {
            void setCardDetails(selectedPaymentMethod, cartId)
              .then(cardResponse => {
                if (cardResponse instanceof Error) {
                  onError(cardResponse);
                } else {
                  setPaymentTypeStatus(true);
                }
              })
              .catch((error: Error) => {
                setIsBusy(false);
                onError(error);
              });
          } else {
            setPaymentTypeStatus(true);
          }
        })
        .catch((error: Error) => {
          setIsBusy(false);
          onError(error);
        });
    }
  }, [
    cartId,
    cartStatusCode,
    onError,
    selectedPaymentMethod,
    setCardDetails,
    setPaymentType
  ]);

  function handleConfirmOrder() {
    setIsBusy(true);
    void createACart()
      .then(response => {
        if (response instanceof Error) {
          onError(response);
        } else {
          setCartId(response);
        }
      })
      .catch((error: Error) => {
        onError(error);
      });
  }

  useEffect(() => {
    if (cartId) {
      void addProductToCart(cartId, product.code)
        .then(statusCode => {
          if (statusCode instanceof Error) {
            onError(statusCode);
          } else {
            setCartStatusCode(statusCode);
          }
        })
        .catch((error: Error) => {
          setIsBusy(false);
          onError(error);
        });
    }
  }, [addProductToCart, cartId, onError, product.code]);

  useEffect(() => {
    if (deliveryAddress && paymentTypeStatus) {
      if (deliveryAddress.id && cartId) {
        void addDeliveryAddress(deliveryAddress.id, cartId)
          .then(response => {
            if (response instanceof Error) {
              onError(response);
            } else {
              setDeliveryAddressStatus(true);
            }
          })
          .catch((error: Error) => {
            setDeliveryAddressStatus(false);
            setIsBusy(false);
            onError(error);
          });
      }
    }
  }, [addDeliveryAddress, deliveryAddress, paymentTypeStatus, cartId, onError]);

  useEffect(() => {
    if (deliveryAddressStatus && paymentTypeStatus && cartId) {
      void createOrder(cartId)
        .then(response => {
          setIsBusy(false);
          if (response) {
            if (response instanceof Error) {
              onError(response);
            } else {
              onSuccess(response);
            }
          }
        })
        .catch((error: Error) => {
          setIsBusy(false);
          onError(error);
        });
    }
  }, [
    cartId,
    createOrder,
    deliveryAddressStatus,
    onError,
    onSuccess,
    paymentTypeStatus
  ]);

  function handleNext() {
    let isStepValid = validStep;
    if (activeStep == 0) {
      if (product.price.amount !== null) {
        setSelectedPrice(product.price.amount);
        isStepValid = true;
      } else {
        isStepValid = selectedPrice !== null;
      }
    } else if (activeStep === 1) {
      isStepValid = deliveryMode !== null && deliveryAddress !== null;
    }
    if (isStepValid) {
      setActiveStep(current => current + 1);
      setValidStep(false);
    }
  }

  function handleBack() {
    setValidStep(true);
    setActiveStep(current => current - 1);
  }

  function handlePriceChange(value: number | null) {
    setSelectedPrice(value);
    if (value) {
      setValidStep(true);
    }
  }

  function handleDeliveryModeChange(value: string | null) {
    setDeliveryMode(value);
    if (value && deliveryAddress) {
      setValidStep(true);
    }
  }

  function handleDeliveryAddressChange(address: Address | null) {
    setDeliveryAddress(address);
    if (address && deliveryMode) {
      setValidStep(true);
    }
  }

  function handlePaymentMethodChange(value: string): void {
    setSelectedPaymentMethod(value);
    if (value) {
      setValidStep(true);
    }
  }

  return (
    <div data-testid="product-checkout-form">
      <ProductDetails product={product} isExpandable={activeStep > -1} />
      {activeStep > -1 && (
        <div className="mb-5 flex justify-center">
          <Stepper
            data-testid="product-checkout-stepper"
            activeStep={activeStep}
            steps={[
              tCheckout("stepper.price"),
              tCheckout("stepper.delivery"),
              tCheckout("stepper.summary")
            ]}
          />
        </div>
      )}

      {activeStep === 0 && (
        <ProductPrice
          productPrice={product.price}
          selectedPrice={selectedPrice!}
          onChange={handlePriceChange}
        />
      )}
      {activeStep === 1 && (
        <Delivery
          deliveryMode={deliveryMode}
          billingAddressId={
            deliveryAddress !== null ? deliveryAddress.id : null
          }
          onBillingAddressChange={handleDeliveryAddressChange}
          onDeliveryModeChange={handleDeliveryModeChange}
        />
      )}

      {activeStep === 2 && (
        <>
          <Summary
            selectedPrice={selectedPrice!}
            productPrice={product.price}
            deliveryMode={deliveryMode!}
            billingAddress={deliveryAddress!}
          />
          <PaymentMethods
            selectedValue={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
          />
        </>
      )}
      <div className="flex flex-row-reverse gap-4 pl-2 pt-4">
        {activeStep < 2 && (
          <Button
            data-testid="next-button"
            onClick={handleNext}
            variant="primary"
            disabled={!validStep}
          >
            {tCommon("button.next.label")}
          </Button>
        )}
        {activeStep === 2 && (
          <Button
            isLoading={isBusy}
            data-testid="purchase-button"
            onClick={handleConfirmOrder}
            variant="accent"
            disabled={!validStep}
          >
            {tCheckout("purchase.button.label")}
          </Button>
        )}
        {activeStep > 0 && (
          <Button data-testid="back-button" onClick={handleBack}>
            {tCommon("button.back.label")}
          </Button>
        )}
      </div>
      {activeStep > -1 && (
        <DiscardPurchaseButton disabled={isBusy} onConfirm={onDiscard} />
      )}
    </div>
  );
};
