import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Button, Typography } from "@destination/components";

import checkIcon from "@icons/checkCircle.svg";

interface Props {
  onFinish: () => void;
}

const OrderCreatedMessage: FunctionComponent<Props> = ({ onFinish }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.confirm_order" });
  return (
    <div
      className="flex flex-col text-center"
      data-testid="ordered-created-message"
    >
      <img
        className="h-6 sm:h-6"
        src={checkIcon}
        alt="check circle"
        data-testid="check-icon"
      />

      <Typography variant="pageTitle" className="mt-5">
        {t("thanks.message")}
      </Typography>

      <Typography variant="description" className="mt-5">
        {t("see_order_details.message")}
      </Typography>

      <div className="mt-5 flex justify-center">
        <Button variant="ghost" data-testid="back-to-store" onClick={onFinish}>
          {t("back_to_store.label")}
        </Button>
      </div>
    </div>
  );
};

export default OrderCreatedMessage;
