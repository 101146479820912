import { useMemo } from "react";

import useSWR from "swr";

import { UserOrganizationsResponse } from "@account/models/UserOrganizationsResponse";

import { fetcher } from "@utils/swrUtils";

import { userEndpoints } from "./endpoints";

export function useGetUserOrganizations() {
  const url = userEndpoints.organizations();

  const { data, error, isLoading } = useSWR<UserOrganizationsResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ organizations: data?.organizations, isLoading, error }),
    [data, isLoading, error]
  );
}
