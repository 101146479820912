import { FC } from "react";

import { Card, Typography } from "@destination/components";

import DeleteAddressButton from "@store/addresses/components/DeleteAddressButton";
import SetDefaultBillingAddressSwitch from "@store/addresses/components/SetDefaultBillingAddressSwitch";
import { Address } from "@store/addresses/models/Address";

interface Props {
  address: Address;
}

const AddressComponent: FC<Props> = ({ address }) => {
  const renderText = (value: string) => {
    return <Typography variant="description">{value}</Typography>;
  };

  return (
    <Card
      className="grid h-32 w-80 grid-cols-2"
      data-testid={`address-${address.id}`}
    >
      <div>
        <Typography variant="smallTitle">
          {address.street} {address.houseNumber}
        </Typography>
        {renderText(address.city)}
        {renderText(address.countryName)}
        {renderText(address.postalCode)}
      </div>
      <div className="relative h-32 w-32">
        <div className="absolute right-0 top-0 h-16">
          {" "}
          <DeleteAddressButton
            addressId={address.id}
            addressName={`${address.street} ${address.houseNumber}`}
          />
        </div>
        <div className="absolute bottom-0 right-0 h-16 w-max">
          <SetDefaultBillingAddressSwitch
            addressId={address.id}
            addressName={`${address.street} ${address.houseNumber}`}
            isDefault={address.isDefaultBillingAddress}
          />
        </div>
      </div>
    </Card>
  );
};

export default AddressComponent;
