import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

const Title: FunctionComponent = () => {
  return (
    <div className="grow text-center" data-testid="header-title">
      <Typography variant="pageTitle">OMNIS portal</Typography>
    </div>
  );
};

export default Title;
