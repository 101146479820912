import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@destination/components";

import CountryDropDown from "@countries/components/CountryDropDown";
import AddressInput from "@store/addresses/components/AddressInput";
import AddressTypes from "@store/addresses/components/AddressTypes";
import { useAddAddress } from "@store/addresses/services/use-addresses-add";

interface Props {
  onSuccess: () => void;
  onError: () => void;
}
const AddNewAddress: FC<Props> = ({ onSuccess, onError }) => {
  const { createAddress } = useAddAddress();
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });
  const { t: tCommon } = useTranslation();
  const [streetName, setStreetName] = useState<string>();
  const [houseNumber, setHouseNumber] = useState<string>();
  const [city, setCity] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [countryIso, setCountryIso] = useState<string | undefined>();
  const [isBillingAddress, setIsBillingAddress] = useState<boolean>(false);
  const [isShippingAddress, setIsShippingAddress] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isStreetNameValid, setIsStreetNameValid] = useState<boolean>(false);
  const [isHouseNumberValid, setIsHouseNumberValid] = useState<boolean>(false);
  const [isCityValid, setIsCityValid] = useState<boolean>(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);
  const [isCountryValid, setIsCountryValid] = useState<boolean>(false);
  const [isAddressTypeValid, setIsAddressTypeValid] = useState<boolean>(false);

  useEffect(() => {
    setIsFormValid(
      isCityValid &&
        isHouseNumberValid &&
        isPostalCodeValid &&
        isStreetNameValid &&
        isCountryValid &&
        isAddressTypeValid
    );
  }, [
    isCityValid,
    isCountryValid,
    isHouseNumberValid,
    isPostalCodeValid,
    isStreetNameValid,
    isAddressTypeValid
  ]);

  const handleStreetNameChange = (newValue: string, isValid: boolean) => {
    setStreetName(newValue);
    setIsStreetNameValid(isValid);
  };
  const handleHouseNumberChange = (newValue: string, isValid: boolean) => {
    setHouseNumber(newValue);
    setIsHouseNumberValid(isValid);
  };
  const handleCityChange = (newValue: string, isValid: boolean) => {
    setCity(newValue);
    setIsCityValid(isValid);
  };
  const handlePostalCodeChange = (newValue: string, isValid: boolean) => {
    setPostalCode(newValue);
    setIsPostalCodeValid(isValid);
  };

  const handleCountryChange = (value: string | undefined) => {
    setCountryIso(value);
    setIsCountryValid(value !== undefined);
  };

  const handleAddressTypeChange = (isBilling: boolean, isShipping: boolean) => {
    setIsBillingAddress(isBilling);
    setIsShippingAddress(isShipping);
    setIsAddressTypeValid(isBilling || isShipping);
  };

  const handleSave = () => {
    void createAddress({
      city: city!,
      street: streetName!,
      countryIsoCode: countryIso!,
      houseNumber: houseNumber!,
      isBillingAddress,
      isShippingAddress,
      postalCode: postalCode!
    })
      .then(response => {
        if (response instanceof Error) {
          onError();
        } else {
          onSuccess();
        }
      })
      .catch(() => {
        onError();
      });
  };
  return (
    <div className="flex flex-col gap-8" data-testid="add-new-address-form">
      <AddressInput
        id="street"
        label={t("street.label")}
        value={streetName}
        onInputChange={handleStreetNameChange}
      />
      <AddressInput
        id="houseNumber"
        label={t("house-number.label")}
        value={houseNumber}
        onInputChange={handleHouseNumberChange}
      />
      <AddressInput
        id="city"
        label={t("city.label")}
        value={city}
        onInputChange={handleCityChange}
      />
      <div className="flex gap-2">
        <div className="w-64 flex-none">
          <AddressInput
            id="postalCode"
            label={t("postal-code.label")}
            value={postalCode}
            onInputChange={handlePostalCodeChange}
          />
        </div>
        <div className="w-80 flex-initial">
          <CountryDropDown
            label={t("country.label")}
            selectedCountryCode={countryIso}
            onChange={handleCountryChange}
          />
        </div>
      </div>
      <AddressTypes onValueChanged={handleAddressTypeChange} />
      <div className="flex flex-row-reverse">
        <Button
          data-testid="save"
          onClick={handleSave}
          disabled={!isFormValid}
          variant="primary"
        >
          {tCommon("button.save.label")}
        </Button>
      </div>
    </div>
  );
};

export default AddNewAddress;
