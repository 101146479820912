import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button, useAlert, useDialog } from "@destination/components";

import { useDeleteAddress } from "@store/addresses/services/use-addresses-delete";

import TrashIcon from "@icons/trash.svg?react";

interface Props {
  addressId: string;
  addressName: string;
}

const DeleteAddressButton: FC<Props> = ({ addressId, addressName }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "store.addresses.delete"
  });
  const { t: tCommon } = useTranslation();
  const { notifyError, notifySuccess } = useAlert();

  const { deleteAddress } = useDeleteAddress();
  const { trigger } = useDialog();

  const handleButtonClick = () => {
    trigger({
      variant: "warning",
      header: t("confirm.title"),
      content: t("confirm.message", {
        address: addressName
      }),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: () => {
        deleteAddress(addressId)
          .then(response => {
            if (response instanceof Error) {
              notifyError({
                header: t("error.message"),
                "data-testid": "delete-address-error"
              });
            } else {
              notifySuccess({
                header: t("success.message", {
                  address: addressName
                }),
                "data-testid": "delete-address-success"
              });
            }
          })
          .catch(() => {
            notifyError({
              header: t("error.message"),
              "data-testid": "delete-address-error"
            });
          });
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        /* Do nothing */
      },
      "data-testid": "delete-address"
    });
  };
  return (
    <Button
      icon={<TrashIcon className="h-3 sm:h-4" />}
      variant="discreet"
      data-testid={`delete-address-button-${addressId}`}
      onClick={handleButtonClick}
    >
      {t("label")}
    </Button>
  );
};

export default DeleteAddressButton;
