import { FunctionComponent } from "react";

import { Typography } from "@destination/components";

import AccountMenu from "@account/components/AccountMenu";

import { useUserContext } from "@hooks/useUserContext";

import "./UserProfile.css";

const UserProfile: FunctionComponent = () => {
  const { user } = useUserContext();

  return (
    <div className="profile-section flex flex-col" data-testid="user-profile">
      <Typography variant="sectionTitle" data-testid="user-name">
        {user.name}
      </Typography>
      <AccountMenu />
    </div>
  );
};

export default UserProfile;
