import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Button, Typography } from "@destination/components";

import ProductAbout from "@store/products/components/ProductAbout";
import ProductCategory from "@store/products/components/ProductCategory";
import ProductDescription from "@store/products/components/ProductDescription";
import ProductName from "@store/products/components/ProductName";
import { Product } from "@store/products/models/Product";

import RightArrow from "@icons/right_arrow.svg?react";

interface Props {
  product: Product;
  onCheckout: () => void;
}

const ProductPopover: FunctionComponent<Props> = ({ product, onCheckout }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.product" });

  const renderPriceDescription = () => {
    return (
      <>
        <div className="inline-flex space-x-2">
          {product.price.amount !== null &&
            renderPrice(
              "onetime",
              t("prices.onetime", {
                currency: product.price.currency,
                value: product.price.amount
              })
            )}
          {product.price.yearly !== null &&
            renderPrice(
              "yearly",
              t("prices.yearly", {
                currency: product.price.currency,
                value: product.price.yearly
              })
            )}
          {product.price.monthly !== null &&
            renderPrice(
              "monthly",
              t("prices.monthly", {
                currency: product.price.currency,
                value: product.price.monthly
              })
            )}
        </div>
        {renderDescription("price", product.price.description)}
        <Button
          variant="discreet"
          className="ml-auto mt-6 flex gap-1.5 !p-3"
          onClick={onCheckout}
          data-testid="more-details"
        >
          {t("more-details")}
          <span className="h-4 w-4">
            <RightArrow />
          </span>
        </Button>
      </>
    );
  };

  const renderPriceSection = (id: string, label: string) => {
    return (
      <div>
        <Typography
          variant="smallTitle"
          className="mt-5 font-bold"
          data-testid={`product-${id}-label`}
        >
          {label}
        </Typography>
        <div className="w-[330px]">{renderPriceDescription()}</div>
      </div>
    );
  };

  const renderDescription = (
    id: string,
    value: string,
    className = "text-wrap"
  ) => {
    return (
      <Typography
        variant="description"
        className={className}
        data-testid={`product-${id}-description`}
      >
        {value}
      </Typography>
    );
  };
  const renderPrice = (id: string, value: string) => {
    return (
      <Typography variant="description" data-testid={`product-prices-${id}`}>
        {value}
      </Typography>
    );
  };

  return (
    <div
      data-testid={`product-details-card-${product.code}`}
      className="gap flex flex-col"
    >
      <ProductName value={product.name} />
      <ProductDescription value={product.description} />
      <ProductAbout value={product.about} />
      <ProductCategory value={product.platform} />
      {renderPriceSection("prices", t("prices.label"))}
    </div>
  );
};

export default ProductPopover;
