import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";

import Dashboard from "@dashboard/components/Dashboard";

import { useUserContext } from "@hooks/useUserContext";

const HomePage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "home" });
  const { user } = useUserContext();

  return (
    <>
      <PageTitle
        title={t("page_title", { userName: user.name })}
        helmetTitle=""
      />
      <Dashboard />
    </>
  );
};

export default HomePage;
