import { OrganizationUser } from "@organization/models/OrganizationUser";

export function filter(
  users: OrganizationUser[] | undefined,
  value: string | undefined
): OrganizationUser[] {
  if (!users) return [];

  if (!value) return users;

  value = value.toLocaleLowerCase();

  return users.filter(
    item =>
      value &&
      (item.displayName.toLocaleLowerCase().includes(value) ||
        item.emailAddress.toLocaleLowerCase().includes(value) ||
        item.organizationRole.toLocaleLowerCase().includes(value))
  );
}
