import { useState } from "react";

import { ValidationResult } from "@validators/models/ValidationResult";

function validate(
  validators:
    | ((value: string) => ValidationResult)
    | ((value: string) => ValidationResult)[],
  value: string
): ValidationResult {
  if (Array.isArray(validators)) {
    const failedResults: ValidationResult[] = [];
    validators.forEach(validator => {
      const result = validator(value);
      if (result.isValid === false) failedResults.push(result);
    });
    if (failedResults.length === 0) return <ValidationResult>{ isValid: true };
    return <ValidationResult>{
      isValid: false,
      errorMessage: failedResults.map(result => result.errorMessage).join(", ")
    };
  }
  return validators(value);
}
export const useInput = (
  validator:
    | ((value: string) => ValidationResult)
    | ((value: string) => ValidationResult)[],
  initialValue = ""
) => {
  const [isTouched, setIsTouched] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: true
  });

  const valueChangeHandler = (
    inputValue: string
  ): { value: string; isValid: boolean; errorMessage: string | undefined } => {
    setIsTouched(true);
    setValue(inputValue);
    const result = validate(validator, inputValue);
    setValidationResult(result);

    return {
      value: inputValue,
      isValid: result.isValid,
      errorMessage: result.errorMessage
    };
  };

  const reset = () => {
    setIsTouched(false);
    setValue(initialValue);
    setValidationResult({ isValid: true });

    return {
      value: initialValue,
      isValid: true
    };
  };

  return {
    value,
    isValid: validationResult.isValid,
    errorMessage: validationResult.errorMessage,
    isTouched,
    valueChangeHandler,
    reset
  };
};
