import { FunctionComponent, ReactNode, useMemo, useState } from "react";

import { Drawer } from "@destination/components";

import { DrawerProps } from ".";
import { DrawerContext } from "./DrawerContext";

interface Props {
  children: ReactNode;
}

const DrawerProvider: FunctionComponent<Props> = ({ children }) => {
  const [drawer, setDrawer] = useState<DrawerProps>();
  const [showDrawer, setShowDrawer] = useState(false);
  const contextValue = useMemo(() => ({ setDrawer, setShowDrawer }), []);

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      <Drawer
        title={drawer?.title ?? ""}
        isOpen={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <div data-testid="drawer-content">{drawer?.content}</div>
      </Drawer>
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
