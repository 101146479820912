import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";

const PageNotFound: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "pagenotfound" });
  return <PageTitle title={t("page_title")} />;
};

export default PageNotFound;
