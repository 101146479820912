import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import SectionTitle from "@components/SectionTitle";

import application from "@icons/application.svg";
import organization from "@icons/organization.svg";

import QuickAccessTile from "./QuickAccessTile";

const QuickAccessPanel: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboard.quick-access"
  });
  const { t: tCommon } = useTranslation();

  return (
    <div className="flex flex-col" data-testid="quickAccessPanel">
      <SectionTitle title={t("section_title")} />
      <div className="flex gap-10">
        <QuickAccessTile
          id="organization"
          icon={organization}
          href="/organization"
          title={tCommon("organization.nav_item.label")}
        />
        <QuickAccessTile
          id="application"
          icon={application}
          href="/applications"
          title={tCommon("applications.nav_item.label")}
        />
      </div>
    </div>
  );
};

export default QuickAccessPanel;
