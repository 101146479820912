import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";

import { useGetOrganizationDetails } from "@organization/services/use-organization";

import checkCircle from "@icons/checkCircle.svg";
import closeCircle from "@icons/closeCircle.svg";

import "./OrganizationInfo.css";

const OrganizationInfo: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization" });

  const [statusIcon, setStatusIcon] = useState<string>();

  const { organization, error, isLoading } = useGetOrganizationDetails();

  useEffect(() => {
    if (organization?.status === "Active") {
      setStatusIcon(checkCircle);
    } else {
      setStatusIcon(closeCircle);
    }
  }, [organization]);

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error || organization == undefined)
    return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div
      className="organization-short-description"
      data-testid="organization-short-description"
    >
      <Typography variant="description">
        <img
          className="h-3 sm:h-4"
          src={statusIcon}
          alt="check circle"
          data-testid="statusIcon"
        />
        {t("info", {
          status: t("status." + organization.status),
          created_on: organization.createdOn
        })}
      </Typography>
    </div>
  );
};

export default OrganizationInfo;
