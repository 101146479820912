import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { IOption, Select } from "@destination/components";

interface Props {
  label: string;
  selectedType: string | undefined;
  disabled?: boolean;
}

const OrganizationTypeDropDown: FunctionComponent<Props> = ({
  label,
  selectedType,
  disabled = false
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "organization" });

  const options = useMemo(() => {
    return [
      { label: t("types.Business.label"), value: "Business" },
      { label: t("types.Internal.label"), value: "Internal" },
      { label: t("types.Personal.label"), value: "Personal" }
    ];
  }, [t]);

  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  useEffect(() => {
    if (selectedType) {
      const foundOption = options.find(
        type => type.value.toString() === selectedType
      );
      setSelectedOption(foundOption ?? null);
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  const handleChange = (option: IOption) => {
    setSelectedOption(option);
  };

  return (
    <Select
      data-testid="organization-type-dropdown"
      label={label}
      placeholder={label}
      disabled={disabled}
      options={options}
      selected={selectedOption}
      onChange={handleChange}
    />
  );
};

export default OrganizationTypeDropDown;
