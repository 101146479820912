import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageHelmet from "@components/PageHelmet";

import Purchases from "@store/purchases/components/Purchases";

const PurchaseHistory: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.purchases" });

  return (
    <div data-testid="purchase-history-container">
      <PageHelmet title={`${t("page_title")}`} />
      <Purchases />
    </div>
  );
};

export default PurchaseHistory;
