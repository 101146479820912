import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "@destination/components";

import { useUserContext } from "@hooks/useUserContext";

const Language: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });
  const { user } = useUserContext();

  const languageName = new Intl.DisplayNames([user.language], {
    type: "language"
  });
  return (
    <div className="mt-8 flex flex-col gap-8">
      <Input
        id="language"
        label={t("language.label")}
        data-testid="language"
        value={languageName.of(user.language)}
        disabled
      />
    </div>
  );
};

export default Language;
