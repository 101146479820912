import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

interface Props {
  id: string;
  value: string;
}

const SubscriptionTextSection: FunctionComponent<Props> = ({ id, value }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.subscription" });

  return (
    <div className="mt-10 text-wrap" data-testid={`subscription-section-${id}`}>
      <Typography variant="description" className="font-bold">
        {t(`${id}.label`)}
      </Typography>
      <Typography variant="description" className="mt-1 text-wrap">
        {value}
      </Typography>
    </div>
  );
};

export default SubscriptionTextSection;
