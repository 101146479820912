import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "@destination/components";

import { isRegExMatch } from "@validators/RegExValidator";

import { useInput } from "@hooks/useInput";

interface Props {
  id: string;
  label: string;
  initialValue: string | undefined;
  onInputChange?: (
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ) => void;
  disabled?: boolean;
}

const UserEmail: FunctionComponent<Props> = ({
  id,
  initialValue,
  label,
  onInputChange,
  disabled
}) => {
  const { t } = useTranslation();

  const [isValidInput, setIsValidInput] = useState<boolean>(true);

  const [email, setEmail] = useState<string | undefined>(initialValue);

  const { isValid, errorMessage, valueChangeHandler } = useInput(
    [
      isRegExMatch(
        /^[a-zA-Z0-9_.!#%&'*+/=?`{|}~^-]+@((?!-)[a-zA-Z\d-]+(?<!-)\.)+((?!\d)[a-zA-Z0-9]{2,})$/g,
        () =>
          t("validation.string.invalid-email", {
            fieldName: label
          })
      )
    ],
    initialValue
  );

  return (
    <Input
      type="email"
      id={id}
      data-testid={id}
      label={label}
      error={!isValidInput}
      helperText={errorMessage}
      value={email}
      disabled={disabled}
      onBlur={event => {
        const { isValid } = valueChangeHandler(event.target.value);
        setIsValidInput(isValid);
      }}
      onChange={event => {
        setEmail(event.target.value);
        if (onInputChange)
          onInputChange(event.target.value, isValid, errorMessage);
      }}
    />
  );
};

export default UserEmail;
