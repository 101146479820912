import axios from "axios";

const scope = import.meta.env.VITE_APP_BACKEND_API_SCOPE as string;
const baseURL = import.meta.env.VITE_APP_BACKEND_API_URL as string;

export const axiosInstance = axios.create({
  baseURL: baseURL
});

export const addUserRequestHeadersInterceptor = (
  userLanguage: string,
  getAccessToken: (scopes: string[]) => Promise<string>
) => {
  return axiosInstance.interceptors.request.use(async config => {
    const token = await getAccessToken([scope]);
    if (token) {
      config.headers.set("Authorization", `Bearer ${token}`);
    }
    config.headers.set("Accept-Language", userLanguage);

    return config;
  });
};

export function ejectRequestInterceptor(interceptorId: number) {
  axiosInstance.interceptors.request.eject(interceptorId);
}
