import { useMemo } from "react";

import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Sidebar } from "@destination/components";

import { useUserContext } from "@hooks/useUserContext";
import ApplicationIcon from "@icons/application.svg?react";
import DocumentationIcon from "@icons/documentation.svg?react";
import HomeIcon from "@icons/home.svg?react";
import OrganizationIcon from "@icons/organization.svg?react";
import SettingIcon from "@icons/settings.svg?react";
import SignOutIcon from "@icons/signOut.svg?react";
import StorefrontIcon from "@icons/storefront.svg?react";
import SupportIcon from "@icons/support.svg?react";

const documentationUrl = import.meta.env.VITE_APP_DOCUMENTATION_URL as string;

export const MenuNavigation = () => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigationItems = useMemo(() => {
    const items = [
      {
        icon: <HomeIcon />,
        label: t("home.nav_item.label"),
        url: "/",
        id: "home"
      },
      {
        icon: <OrganizationIcon />,
        label: t("organization.nav_item.label"),
        url: "/organization",
        id: "organization"
      },
      {
        icon: <ApplicationIcon />,
        label: t("applications.nav_item.label"),
        url: "/applications",
        id: "applications"
      }
    ];
    if (user.isAdministrator) {
      items.push({
        icon: <StorefrontIcon />,
        label: t("store.nav_item.label"),
        url: "/store",
        id: "store"
      });
    }
    return items;
  }, [t, user]);

  return <Navigation items={navigationItems} />;
};

export const FooterNavigation = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const handleLogout = () => {
    void instance.logout();
  };

  const footerItems = [
    {
      icon: <SupportIcon />,
      label: t("support.nav_item.label"),
      url: "/support",
      id: "support"
    },
    {
      icon: <SettingIcon />,
      label: t("settings.nav_item.label"),
      url: "/settings",
      id: "settings"
    },
    {
      icon: <DocumentationIcon />,
      label: t("documentation.nav_item.label"),
      url: documentationUrl,
      target: "_blank",
      id: "documentation"
    },
    {
      icon: <SignOutIcon />,
      label: t("signOut.nav_item.label"),
      url: "/signOut",
      id: "signOut",
      onMenuClick: handleLogout
    }
  ];

  return <Navigation items={footerItems} />;
};

const Navigation = ({
  items
}: {
  items: {
    id: string;
    icon: React.ReactNode;
    label: string;
    url: string;
    onMenuClick?: () => void;
    target?: string;
  }[];
}) => {
  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ icon, id, label, target, url, onMenuClick }) => (
        <NavLink
          key={`navigation-${id}`}
          to={url}
          data-testid={`navigation-${id}`}
          target={target}
          onClick={() => (onMenuClick ? onMenuClick() : undefined)}
        >
          {({ isActive }) => (
            <Sidebar.MenuItem icon={icon} label={label} isActive={isActive} />
          )}
        </NavLink>
      ))}
    </nav>
  );
};
