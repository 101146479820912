import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@destination/components";

import { usePaymentDetails } from "@store/payments/services/PaymentService";

import useDrawer from "@hooks/useDrawer";
import PlusIcon from "@icons/plus.svg?react";

const AddPaymentMethodButton: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "store.payment-methods"
  });

  const { getRegistrationSession } = usePaymentDetails();
  const { showDrawer } = useDrawer();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const handleAddPaymentMethod = () => {
    setIsBusy(true);

    void getRegistrationSession().then(response => {
      if (response && !(response instanceof Error)) {
        showDrawer({
          title: "",
          content: (
            <div className="relative h-[500px] w-full overflow-hidden pt-5">
              <iframe
                width="100%"
                height="100%"
                src={response.paymentCardRegistrationURL}
                title="payment"
              ></iframe>
            </div>
          )
        });
      }
      setIsBusy(false);
    });
  };
  return (
    <Button
      data-testid="add-payment-method-button"
      icon={<PlusIcon />}
      isLoading={isBusy}
      variant="primary"
      onClick={handleAddPaymentMethod}
    >
      {t("add.label")}
    </Button>
  );
};

export default AddPaymentMethodButton;
