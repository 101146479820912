import { FunctionComponent } from "react";

import Title from "./Title";
import UserProfile from "./UserProfile";

const Header: FunctionComponent = () => {
  return (
    <div className="z-50 flex flex-row pt-2">
      <Title />
      <UserProfile />
    </div>
  );
};

export default Header;
