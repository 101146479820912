import { FunctionComponent } from "react";

import checkCircleBlue from "@icons/checkBlueCircle.svg";
import checkCircle from "@icons/checkCircle.svg";
import closeCircle from "@icons/closeCircle.svg";
import minusCircle from "@icons/minus-circle.svg";

interface Props {
  state: string;
}

const UserStatusIcon: FunctionComponent<Props> = ({ state }) => {
  return (
    <>
      {state === "Active" && (
        <img
          className="h-3 sm:h-4"
          src={checkCircle}
          alt="check circle"
          data-testid="activeImage"
        />
      )}
      {state === "Invited" && (
        <img
          className="h-3 sm:h-4"
          src={checkCircleBlue}
          alt="check circle"
          data-testid="invitedImage"
        />
      )}
      {state === "Inactive" && (
        <img
          className="h-3 sm:h-4"
          src={closeCircle}
          alt="check circle"
          data-testid="inactiveImage"
        />
      )}
      {state === "InvitationExpired" && (
        <img
          className="bg-red h-3 sm:h-4"
          src={minusCircle}
          alt="check circle"
          data-testid="expiredImage"
        />
      )}
    </>
  );
};

export default UserStatusIcon;
