import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";

import StoreTabMenu from "@store/products/components/StoreTabMenu";

const StoreHomePage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store" });

  return (
    <div>
      <PageTitle title={t("page-title")} />
      <div className="pt-8">
        <StoreTabMenu />
      </div>
    </div>
  );
};

export default StoreHomePage;
