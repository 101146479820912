import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";

import BillingAddresses from "@store/checkout/components/BillingAddresses";
import DeliveryMode from "@store/checkout/components/DeliveryMode";
import { Address } from "@store/checkout/models/Address";

interface Props {
  deliveryMode: string | null;
  billingAddressId: string | null;
  onDeliveryModeChange: (value: string | null) => void;
  onBillingAddressChange: (address: Address | null) => void;
}

const Delivery: FunctionComponent<Props> = ({
  deliveryMode,
  billingAddressId,
  onDeliveryModeChange,
  onBillingAddressChange
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.checkout" });
  return (
    <div data-testid="delivery">
      <Card>
        <DeliveryMode
          deliveryMode={deliveryMode}
          onChange={onDeliveryModeChange}
        />

        <Typography variant="smallTitle" className="mt-5 font-bold">
          {t("billing-address.label")}
        </Typography>

        <BillingAddresses
          onChange={onBillingAddressChange}
          addressId={billingAddressId}
        />
      </Card>
    </div>
  );
};

export default Delivery;
