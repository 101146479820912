import { FunctionComponent, ReactNode } from "react";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import ErrorPage from "@pages/ErrorPage";
import LoadingPage from "@pages/LoadingPage";

import { loginRequest } from "../../authConfig";

interface Props {
  children: ReactNode;
}

function ErrorComponent() {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });

  return <ErrorPage message={t("sigin.error.message")} />;
}

function Authenticating() {
  const { t } = useTranslation(undefined, { keyPrefix: "account" });

  return <LoadingPage message={t("authenticate.message")} />;
}

const RequireAuth: FunctionComponent<Props> = ({ children }) => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={Authenticating}
      errorComponent={ErrorComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default RequireAuth;
