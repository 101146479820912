import { FunctionComponent, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import SectionTitle from "@components/SectionTitle";

import { Application } from "@applications/models/Application";
import { useGetAssociatedApplications } from "@applications/services/ApplicationService";

import abb from "@icons/abb.svg";

import QuickAccessTile from "./QuickAccessTile";

const ApplicationsPanel: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboard.applications"
  });
  const { t: tApplications } = useTranslation(undefined, {
    keyPrefix: "organization.applications"
  });

  const { applications, error, isLoading } = useGetAssociatedApplications();

  const renderChildren = useCallback(
    (children: ReactNode): ReactNode => {
      return (
        <div className="flex flex-col">
          <SectionTitle title={t("section_title")} />
          <div className="flex gap-10">{children}</div>
        </div>
      );
    },
    [t]
  );

  if (isLoading)
    return <LoadingComponent message={tApplications("load.message")} />;
  if (error)
    return <ErrorComponent message={tApplications("load.error.message")} />;

  if (applications?.length === 0)
    return (
      <div className="flex py-10">
        <ErrorComponent message={tApplications("load.none.message")} />
      </div>
    );

  if (applications)
    return renderChildren(
      <div className="flex flex-wrap gap-10" data-testid="applicationsPanel">
        {applications.map((application: Application) => (
          <QuickAccessTile
            key={application.id}
            id={application.id}
            icon={abb}
            href={application.url!}
            title={application.name}
            target="_blank"
          />
        ))}
      </div>
    );
};

export default ApplicationsPanel;
