import { FunctionComponent } from "react";

import { Card, Typography } from "@destination/components";

import CreditCardComponent from "@store/creditcard/components/CreditCard";
import { CreditCardDetail } from "@store/creditcard/models/CreditCardDetail";

import DeletePaymentMethodButton from "./DeletePaymentMethodButton";

interface Props {
  paymentDetail: CreditCardDetail;
}

const PaymentDetail: FunctionComponent<Props> = ({ paymentDetail }) => {
  return (
    <Card
      data-testid={`payment-detail-${paymentDetail.id}`}
      className="mb-5 ml-5 mt-5 flex w-[350px]"
      key={paymentDetail.id}
    >
      <div className="grow">
        <Typography variant="smallTitle">
          {paymentDetail.accountHolderName}
        </Typography>
        <CreditCardComponent paymentDetail={paymentDetail} />
      </div>
      <div>
        <DeletePaymentMethodButton
          paymentDetailId={paymentDetail.id}
          cardHolderName={paymentDetail.accountHolderName}
        />
      </div>
    </Card>
  );
};

export default PaymentDetail;
