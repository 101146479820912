import { FunctionComponent, ReactNode } from "react";

import { UnAuthorizedPage } from "@account/pages/UnAuthorizedPage";

import { useUserContext } from "@hooks/useUserContext";

interface Props {
  children: ReactNode;
}
const IsAdministrator: FunctionComponent<Props> = ({ children }) => {
  const { user } = useUserContext();

  if (!user.isAdministrator) {
    return <UnAuthorizedPage />;
  }
  return <>{children}</>;
};

export default IsAdministrator;
