import { ChangeEvent, FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox } from "@destination/components";

interface Props {
  onValueChanged: (isBilling: boolean, isShipping: boolean) => void;
}

const AddressTypes: FC<Props> = ({ onValueChanged }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.addresses" });

  const [isBilling, setIsBilling] = useState<boolean>(false);
  const [isShipping, setIsShipping] = useState<boolean>(false);

  useEffect(() => {
    onValueChanged(isBilling, isShipping);
  }, [isBilling, isShipping, onValueChanged]);

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;
    if (event.target.name === "billing") setIsBilling(checkedValue);
    else setIsShipping(checkedValue);
  };

  const renderCheckBox = (label: string, id: string, isChecked: boolean) => {
    return (
      <Checkbox
        label={label}
        data-testid={id}
        name={id}
        variant="alternative"
        checked={isChecked}
        onChange={handleCheckChange}
      />
    );
  };

  return (
    <div className="flex gap-2" data-testid="address-types">
      {renderCheckBox(t("billing-address.label"), "billing", isBilling)}
      {renderCheckBox(t("shipping-address.label"), "shipping", isShipping)}
    </div>
  );
};

export default AddressTypes;
