import { useCallback, useMemo } from "react";

import { useSWRConfig } from "swr";

import { organizationUserEndpoints } from "@api/Endpoints";
import { AddUserToOrganizationResponse } from "@users/organizationuser/models/AddUserToOrganizationResponse";
import { InviteOrganizationUser } from "@users/organizationuser/models/InviteOrganizationUser";
import { UpdateUserRole } from "@users/organizationuser/models/UpdateUserRole";

import { ApiResponse } from "@hooks/ApiResponse";
import deleteData from "@hooks/useDeleteData";
import { putData } from "@hooks/usePutData";

export function useInviteOrganizationUser() {
  const { mutate } = useSWRConfig();

  const invite = useCallback(
    async (
      inviteOrganizationUser: InviteOrganizationUser
    ): Promise<ApiResponse<AddUserToOrganizationResponse>> => {
      const url = organizationUserEndpoints.addUser();
      const result = await putData<
        InviteOrganizationUser,
        AddUserToOrganizationResponse
      >(url, inviteOrganizationUser);

      const getUrl = organizationUserEndpoints.organizationUsers();
      await mutate(getUrl);

      return result;
    },
    [mutate]
  );

  return useMemo(
    () => ({
      invite
    }),
    [invite]
  );
}

export function useUpdateOrganizationUser(userId: string) {
  const { mutate } = useSWRConfig();

  const update = async (updateUserRole: UpdateUserRole) => {
    const url = organizationUserEndpoints.updateUserRole(userId);
    const result = await putData<UpdateUserRole, string>(url, updateUserRole);

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { update };
}

export function useRemoveOrganizationUsers() {
  const { mutate } = useSWRConfig();

  const deleteUser = async (userId: string) => {
    const url = organizationUserEndpoints.removeUser(userId);
    const result = await deleteData(url);

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { deleteUser };
}
